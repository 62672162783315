body{
    margin: 0;
    padding: 0;
  
    box-sizing: border-box;
    font-family :
    system-ui
    -apple-system
    "Segoe UI"
    Roboto
    "Helvetica Neue"
    "Noto Sans"
    "Liberation Sans"
    Arial
    sans-serif
    "Apple Color Emoji"
    "Segoe UI Emoji"
    "Segoe UI Symbol"
    "Noto Color Emoji" !important;
     height: auto;
     overflow: auto
   

}
.link{
    text-decoration: none;
}
/* Header-Component starting */
.Header-Component{
    background-color: rgb(37,47,130);
    height: 35px;
}
.email{
    display: flex;
    justify-content: end;
    align-items: center;
}
.email .navbar-navs{
    width: 100%;
}
.Header-Component .email div .nav .nav-link{
    color: white;
   
}
.Header-Component .email .nav{
    color: #ffffff;
    position: relative;
    left: 4%;
   
  
}
.email .nav .nav-link{
    color: #ffffff;
    letter-spacing: 1px;
}
.Header-Component .icons {
    display: flex;
   justify-content: end;

}
.Header-Component .icons  .nav .nav-link{
    color: white;
    padding: 10px;
   
}
.icons .nav{
    position: relative;
    right: 5%;
}

/* Header-Component-CSS --ENDING--- */


/* Navbar-Component-CSS --Starting-- */
.dark-color{
    background-color: white !important;
}
.navbar{
    box-shadow: 0px 0px  6px rgb(194, 194, 194);
    padding: 0px !important;

}
.email .nav-link{
    font-size: 14px;
}
#responsive-navbar-nav .nav-link{
    color: black !important;
}
.navbar-toggler{
    position: relative;
    right: 5%;
}
.navbar-brand{
    position: relative;
    left: 4%;
    padding: 0px !important;

}
.navbar-brand img{
    width: 205px;
}
.navbar-left{
    display: flex;
    justify-content: flex-end !important;
    position: relative;
    right: 4%;
}

.nav-item{
    padding-left: 35px;
}
/* .navbar .navbar-nav .dropdown .nav-link:hover .dropdown-menu{
    display: block;

}
.dropdown:hover .dropdown-menu {
    display: block;
}
 .dropdown:hover .dropdown-menu .dropdown-menu{
    display: none;
 }
 .dropdown .dropdown-menu .dropdown-submenu-container:hover .dropdown-menu{
    display: block;
 } */
 
.dropdown-toggle::after {
    display: none !important;
}
.dropdown-item{
    font-size: 12px;
    color: black !important;
}
.dropdown-item a{
    text-decoration: none;
    color: black !important
}
.dropdown-item:hover{
    background-color: #00326D !important;
}
.dropdown-item:hover a{
    color: white !important;


}



.sticky-top{
    background-color: white;
}
/* Navbar-Component-CSS --Ending-- */



/* Homepage OurBussiness Section starting */

.ourBusiness {
    background-color:white;

}
.our-business{
    background-color: #dde8ef  !important;
    height: 150px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none !important;
    position:relative;
    overflow:hidden;
    margin:0 auto;
    background:#333;
    padding:5px 20px 20px 20px;
    box-sizing:border-box;
    text-align:center;
    height: 190px !important;
    margin-bottom: 6% !important;

  
}
.our-business  .layer{
    position:absolute;
    top:calc(100% - 3%);
    height:100%;
    width:100%;
    left:0;
     background:white !important;
    /* transition: 0.9s!important; */
    transition: 2s!important;

    
    box-shadow: rgba(14, 116, 234, 0.4) 0px 0px 0px 2px, rgba(9, 111, 227, 0.65) 0px 4px 6px -1px, rgba(236, 8, 8, 0.08) 0px 1px 0px inset;
}
.our-business .content {
    z-index:2; 
    position:relative;
  }
  .our-business:hover{
    /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

 .our-business:hover  .layer{
    top:0 !important;
  }
  
  .icon-path-div{
    z-index: 2;
    color:red;
    display: flex;
    justify-self: center;
    padding: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: -4%;
    margin-left: 9%;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 1s!important;



    
  }
  .icon-path-div svg{
    font-size: 20px !important ;
  }
  .our-business-col:hover .icon-path-div{
    background-color: #009AA5 !important;
    margin-top: -3.5%;


  }
  .our-business-col:hover .icon-path-div path{
    color: white !important;
  }
  .icon-path-div path{
    color:#009AA5;
  }
  /* .icon-right-arrow{
    display: flex;
    justify-content: center;
    z-index: 1;
    position: absolute;
   

    
  } */

  
.col-lg-3{
    padding: 10px; 
}
.ourBusiness .row{
    display: flex;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
}
.ourBusiness .row .link{
    text-decoration: none;
    
}
.icon-color{
  color:  #009AA5;
}
h6{
    color:#604A50;
    margin-top: 4% !important;
}
/* .ourBusiness{
    margin-bottom: 7%;
} */

/* Homepage OurBussiness Section endting */

/* inside-Page Starting  */
.inside-col{
  margin: 3% 0% 3% 0% !important;
}
.inside-page-content{
    width: 95%;
    font-size: 14px;
    position: relative;
    left: 4%;
    text-align: justify;  
}
.inside-page-col-link{
    margin: 3% 0% 3% 0% !important;
}
.inside-page-link-main p{
    position: relative;
}

.inside-page-links a{
    text-decoration: none;
    color: black;
    margin-left: 5%;
    
}
.inside-page-links a:hover{
    color: #fff;
}
.inside-page-links p{
    font-weight: 500;
    font-size: 14px;
    box-shadow: inset 0 0 0 0 #54b3d6;
    transition: color .6s ease-in-out, box-shadow .6s ease-in-out;

}

.inside-page-link-main{
    height: 100%;
    border-left: 1px solid black;
    width: 90%;
}

.inside-page-links p:hover{

    box-shadow: inset 300px 0 0 0 #54b3d6;;   
}

/* inside-Page Ending  */

/* international-page Starting-Page */
.international-page .nav{
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 3.4%;
    margin-top: 2%;
}
.international-page .nav img{
    width: 35px;
    height: 20px;
}
.international-page a{
    text-decoration: none;
    color: #604A50;

}
.international-page .col-lg-1 p{
    font-size: 10px;
    margin-top: 15%;
    color: black;
}
.international-page .col-lg-6{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
}
.international-page .col-lg-6 p{
    margin: 0px;
    line-height: 35px;
}
.international-btn{
    padding: 2px !important;
    border-radius: 0px !important;
}
.view-all-btn{
    display: flex;
    justify-content: flex-end;
    padding: 0px !important;
}
.qatar-img{
    border: 1px solid lightgray;
}
/* international-page Ending-page */


/* Managemnet-page starting */
.testimonial-card .card-up {
    height: 100px;
    overflow: hidden;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    
  }
  .aqua-gradient {
    background: linear-gradient(40deg, #2096ff, rgb(18,59,139)) !important;
  }
  
  .testimonial-card .avatar {
    width: 120px;
    margin-top: -60px;
    overflow: hidden;
   
  
  }
  .testimonial-card .avatar .card-body{
    height: 130px !important;
  }

  .card-shodow{
    box-shadow: none !important;
    border: none !important;
  }
  .card{
    --bs-card-border-color:none;
  }
  .management .card{
    background-color:rgb(220,245,250) !important;
     --bs-card-border-color:none !important; 
    
     background-size: 200%;
  
    
     min-height: 150px !important;
  } 
  .Management-card-col{
    width: 300px;
    margin-left:0% !important;
  }
  .management-row{
    display: flex;
    justify-content: center;
  }

/* Managemnet-page ending */
/* Vision-page starting */
.icon-colors{ 
    font-size: 12px;
    border-radius: 50%;
    background-color: #252F82;
    color: white;
    padding: 12px;
    width: 50px;
    height: 50px;
    margin-bottom: 4%;

}
.vision-container .vision-div{
    display: inline-grid;
    justify-content: center;
    position: relative;
    left: 30%;
}

/*vision-page ending */
 
/*Media Image-gallery starting */
.Feaild{
  
     margin-top: 3% !important;
     margin-bottom: 1%;
    display: flex;
    justify-content: center;
    
}
.Feaild .col-lg-2{
    padding: 0px;
    width: 150px;
    display: flex;
    justify-content: center;
}
.images-row{
    display: flex;
    justify-content: center;

}
.images-row .col-lg-3{
    padding: 0px;
    width: 270px;
    display: flex;
    justify-content: center;
    padding-top: 25px;
}

.hover-img {
    color: #fff;
    display: inline-block;
    
    overflow: hidden;
    position: relative;
  }
  
  .hover-img * {
    box-sizing: border-box;
    transition: all 0.45s ease;
  }
  
  .hover-img:before,
  .hover-img:after {
    background-color: rgba(0, 0, 0, 0.6);
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    bottom: 0;
    left: 10%;
    right: 0;
    content: '';
    transition: all 0.3s ease;
    z-index: 1;
    opacity: 0;
    transform: scaleY(2);
  }
  
  .hover-img img {
    vertical-align: top;
    backface-visibility: hidden;
  }
  
  .hover-img figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1em;
    opacity: 0;
    z-index: 2;
    transition-delay: 0.1s;
    font-size: 24px;
    font-family: sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .hover-img:hover:before,
  .hover-img:hover:after {
    transform: scale(1);
    opacity: 1;
  }
  
  .hover-img:hover > img {
    opacity: 0.7;
  }
  
  .hover-img:hover figcaption {
    opacity: 1;
   
   
  }



/* Media Image-gallery Ending */





/* Contact-page Starting */


.contact-page .row .form-col{
    padding: 0px;
    display: flex;
    justify-content: end;
}

form{
    width: 88%;
    background-color: #F7F7F7;
    padding: 30px;
}
.contact-map-div{
    width: 92%;
    background-color: #F7F7F7;
    padding: 10px;
}
.contact-map-div-map{
    width: 100%;
}
.message-form{
    height: 100px;
}
.contact-page-icon-1{
 
    position: relative;
    left: 8%;
}
.icon-bg{
    background-color: #E2DEDE;
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    color: skyblue;
    font-size: 30px;
    
}
span{
    font-size: 14px;
}

/* Contact-page ending */




/* Contact-Banner starting*/
.Contact-Banner{
   background-color:rgb(37,47,130);
  
}
.Contact-Banner .col-lg-7{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.Contact-Banner .col-lg-7 div p{
    margin: 0px !important;
    font-size: 24px;
    color: white;
}


.Contact-Banner .col-lg-5  .btn{
    --bs-btn-bg: none !important;
    --bs-btn-border-color: white !important;
    padding: 10px;
    
    
   
}
.Contact-Banner .col-lg-5  .btn h3{
    
margin-bottom: 0px !important;
    
    
   
}
.Contact-Banner .col-lg-5{
    display: flex;
    align-items: center;
}


/* Contact-Banner ending */




/* Footer-Container css Starting */
.footer-container {
    background-color: rgb(99,97,97);
    color: white;
   
    
}
.footer-container h6{
    color: white;
}
.footer-container p{
    margin: 0px;
    padding-top: 10px;
}
.footer-container .row .col-lg-3 {
    display:inline-grid;
    justify-content: center;
   
}
.footer-icons .col-lg-3{
    padding-top: 0px ;
}
.footer-container a{
    text-decoration: none;
    color: white;
}

/* Footer-Container CSS ending */




















/* Media Querrys 320-480 SCREEN -- STARTING */

@media screen and (min-device-width:320px) and (max-device-width:480px) {  
    .Header-Component .email{
        display: flex;
        justify-content: center;

    }
    .email .nav{
        position: relative;
        left: 0% !important;
    }
    .Header-Component .icons{
        display: flex;
        justify-content: center;
    }
    .icons .nav{
        position: relative;
        right: 0% !important;
    }
    .navbar-left{
        display: flex;
        justify-content: flex-start !important;
        position: relative;
        right: 4%;
    }
    .Contact-Banner .col-lg-7{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Contact-Banner .col-lg-7 div p{
        margin: 0px !important;
        font-size: 16px;
        color: white;
    }
    
    
    .Contact-Banner .col-lg-5  .btn{
        --bs-btn-bg: none !important;
        --bs-btn-border-color: white !important;
        padding: 8px;
        
       
    }
    .Contact-Banner .col-lg-5{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }

    /* footer  start*/
    .footer-container {
        background-color: rgb(99,97,97);
        color: white;
       
        
    }
    .footer-container h6{
        color: white;
    }
    .footer-container p{
        margin: 0px;
        padding-top: 10px;
    }
    .footer-container .row .col-lg-3 {
        display:flex;
        justify-content: start;
       
    }
    .footer-icons .col-lg-3{
        padding-top: 0px ;
    }
   
    /* footer ending*/
    .inside-page-content  h4{
       font-size: 15px;
    }
    .inside-page-content{
        width:100%;
        font-size: 14px;
        position: relative;
        left: 0%;

        text-align: justify;
       

       
       
    }
    .inside-col{
        padding: 5% !important;
       
    }
   
    .inside-page-link-main{
        position: relative;
        left: 2%;
    }
    .Feaild .col-lg-2{
        padding: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 5%;
    }
   
    
    .vision-container .vision-div{
        display: inline-grid;
        justify-content: center;
        position: relative;
        left: 3%;
    }
    .Management-card-col{
        margin-bottom: 4%;
       
       
        
    }
    .Management-card-2-col-2{
        margin-bottom: 4%;
       
    }
    .contcat-map{
        display: flex;
        justify-content: center;
        margin-top: 0px !important;
    }
    .navbar-brand img{
        width: 150px;
        height: auto;
    }
    .international-page .col-lg-6 {
        display: flex;
        justify-content: flex-start;
        margin-top: 0px;
    }
    .view-all-btn{
        display: flex;
        justify-content: center;
    }
    .view-all-btn .btn{
     font-size: 12px;   
 }

}


/* Media Querrys 320-480 SCREEN -- ENDING */

/* Media Querrys 481-767 SCREEN -- STARTING */

@media screen and (min-device-width:481px) and (max-device-width:767px){
    .Header-Component .email{
        display: flex;
        justify-content: center;
    }
    .Header-Component .icons{
        display: flex;
        justify-content: center;
    }
    .navbar-left{
        display: flex;
        justify-content: flex-start !important;
        position: relative;
        right: 4%;
    }
    .email .nav{
        position: relative;
        left: 0% !important;
    }
    .icons .nav{
        position: relative;
        right: 0% !important;
    }
    .Contact-Banner .col-lg-7{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Contact-Banner .col-lg-7 div p{
        margin: 0px !important;
        font-size: 18px;
        color: white;
    }
    
    
    .Contact-Banner .col-lg-5  .btn{
        --bs-btn-bg: none !important;
        --bs-btn-border-color: white !important;
        padding: 12px;
        
       
    }
    .Contact-Banner .col-lg-5{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
   
    /* footer  start*/
    .footer-container {
        background-color: rgb(99,97,97);
        color: white;
       
        
    }
    .footer-container h6{
        color: white;
    }
    .footer-container p{
        margin: 0px;
        padding-top: 10px;
    }
    .footer-container .row .col-lg-3 {
        display:flex;
        justify-content: start;
       
    }
    .footer-icons .col-lg-3{
        padding-top: 0px ;
    }
    .international-footer div{
       
    }
    /* footer ending*/
    
    
    .inside-page-link-main{
        position: relative;
        left: 6%;
    }
    .Feaild .col-lg-2{
        padding: 0px;
        width: 100px;
        display: flex;
        justify-content: center;
    }
    .vision-container .vision-div{
        display: inline-grid;
        justify-content: center;
        position: relative;
        left: 1%;
    }
    .contcat-map{
        display: flex;
        justify-content: center;
        margin-top: 0px !important;
    }
    
    
  
}

/* Media Querry Starting 768 and 991 */


@media screen and (min-device-width:768px) and (max-device-width:991px) {
    .Contact-Banner .col-lg-7{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Contact-Banner .col-lg-7 div p{
        margin: 0px !important;
        font-size: 20px;
        color: white;
    }
    
    
    .Contact-Banner .col-lg-5  .btn{
        --bs-btn-bg: none !important;
        --bs-btn-border-color: white !important;
        padding: 12px;
        
       
    }
    .Contact-Banner .col-lg-5{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    .footer-icons{
        position: relative;
        right: 40%;
    }
    .inside-page-content  h4{
        font-size: 18px;
     }
     .Feaild .col-lg-2{
        padding: 0px;
        width: 120px;
        display: flex;
        justify-content: center;
    }
    .images-row .col-lg-3{
        padding: 0px;
        width: 270px;
        display: flex;
        justify-content: center;
        padding-top: 25px;
    }
    .contcat-map{
        display: flex;
        justify-content: center;
        margin-top: 0px !important;
    }
    .international-page .col-md-6{
        display: flex !important;
        justify-content: center !important;
       
       
    }
    .navbar-left {
        display: flex;
         justify-content: flex-start !important;
        position: relative;
        left: 0%;
    }
   
}


/* Media Querry Ending 768 and 991 */


/* Media Querry 992px to  */


.banners{
    height: 100px;
    
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
     appearance: none;
    margin: 0; 
}

@media screen and (max-width: 992px) {
    .icon-path-div {
        display: none;
    }
}
.Feaild-p{
    cursor: pointer;
}
.Feaild-p:hover{
    color:blue;
}
.footer-div-size{
    width: 217px;
}
.div-1{
    height: 55px;
}
.contact-page .row-1{
    display: flex;
    justify-content: center;

}

.icon-div{
    background-color: #F7F7F7;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.icon-div svg{
    font-size: 25px;
    color: #00326D;
}
.row-2 .col-lg-4{
    display: grid;
    justify-content: center;
    
}
.row-2 .div-content{
    width: 220px;
    text-align: center;
    height: 85px;
}
.contcat-map{
    padding: 0px !important;
}
.row-left-contact{
    position: relative;
    right: 1%;;
}


.home-about{
    background-color: #00326D;
    padding: 80px 65px !important;
    color: white;

}
.home-about .row h3{
    border-bottom: 1px solid white;
    padding: 0px 0px 20px 0px!important;
}
.class-color{
    background-color: rgb(62, 69, 132);
    padding: 30px !important;
    text-align: justify;
    height: 380px;
}

.flip-card {
    background-color: transparent;
   
    height: 400px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color:whte;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  
  .flip-card-back {
    background-color: #00326D;
    color: white;
    transform: rotateX(180deg);
    display: grid;
    justify-content: center;
    align-items: center;
    height: 400px;

  }
  .image-our{
    padding: 0px !important;
  }
  .flip-col{
    padding: 20px  10px 10px 10px!important;
  }
  .title-name-back{
    font-weight: 600;
  }
  .icon-color{
    color: #009AA5 !important;
  }

  .title-name-front{
    font-weight: 600;
    color: #00326D !important;
    text-transform: uppercase;
  }
  option{
    color: #6b6f73;
    font-size: 14px;
    text-transform: capitalize;
  }
  a{
    text-decoration: none !important;
  }
  #contact-margin{
    margin-bottom: 0px;
  }
  

  .smooth-dropdown .dropdown-menu {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    /* You can adjust the transition duration and easing as needed */
  }
  
  .smooth-dropdown:hover .dropdown-menu {
    opacity: 1;
    transform: translateY(0);
  }


  @media screen and (min-width: 993px) and (max-width: 2060px) {

    .navbar{
        height: 80px;
    }
    .navbar-left{
        height: 100%;
    }
    .navber-nav-menus {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .navber-nav-menus .nav-item{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .dropdown-menu[data-bs-popper] {
        top: 85% !important;
        left: 0;
        margin-top: var(--bs-dropdown-spacer);
    }

    .dropmenu-leftside .dropdown-menu{
        left: 100% !important;
        top: 0% !important;
    }
   
  }
 
.dropmenu-leftside a{
    padding: 5px !important;
    font-size: 12px;
}
.dropmenu-leftside {
    padding: 0px !important;
    font-size: 12px;
}
.dropdown-item:hover{
    color: white !important;
    background-color: #00326D !important;
}
.dropmenu-leftside:hover{
    background-color: #00326D;
}
.dropmenu-leftside:hover .navbar-links-our{
    color: white !important;
}
.dropdown-menu{
    padding: 5px !important;
}


  
  

  
  